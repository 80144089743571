import axios from "axios";

let BASE_URL =
  process.env.REACT_APP_ENV == "production"
    ? process.env.REACT_APP_API_URL_PRODUCTION
    : process.env.REACT_APP_API_QA_URL;

const responseFormatter = (status, data, error) => {
  return { status, data, error };
};

export const postApiReq = async (url, data) => {
  const route_url = BASE_URL + url;

  const token = sessionStorage.getItem("token");

  return await axios
    .post(route_url, data, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch((e) => {
      if (e?.response?.status === 403) {
        sessionStorage.removeItem("token");
        window.location.href = "/";
      } else if (e) {
        return responseFormatter(false, null, e?.response?.data || null);
      } else {
        return responseFormatter(false, null, e?.response?.data || null);
      }
    });
};

export const getApiReq = async (url) => {
  const token = sessionStorage.getItem("token");
  const route_url = BASE_URL + url;
  return await axios
    .get(route_url, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch((e) => {
      if (e.response.status === 403) {
        sessionStorage.removeItem("token");
        window.location.href = "/";
      } else if (e) {
        return responseFormatter(false, null, e?.response?.data || null);
      } else {
        return responseFormatter(false, null, e?.response?.data || null);
      }
    });
};

export const postReq = async (url, data) => {
  const route_url = BASE_URL + url;
  return await axios
    .post(route_url, data, {
      headers: {
        Accept: "application/json",
      },
    })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch((e) => {
      if (e) {
        return responseFormatter(false, null, e?.response?.data || null);
      } else {
        return responseFormatter(false, null, e?.response?.data || null);
      }
    });
};

export const getReq = async (url) => {
  const route_url = BASE_URL + url;
  return await axios
    .get(route_url, {
      headers: {
        Accept: "application/json",
      },
    })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch((e) => {
      if (e) {
        return responseFormatter(false, null, e?.response?.data || null);
      } else {
        return responseFormatter(false, null, e?.response?.data || null);
      }
    });
};
